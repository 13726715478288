<template>
  <div class="a-detail" v-loadingx="loading">
    <div class="top-c">
      <div><span class="title">{{appraisalData ? appraisalData.title : ''}}</span><span class="note">{{appraisalData ? appraisalData.statusText : ''}}</span></div>
      <div class="remark">{{appraisalData ? appraisalData.remark : ''}}</div>
      <div class="remark">总票数: {{appraisalData ? appraisalData.rule : ''}}</div>
    </div>
    <div class="bottom-c" v-if="userType.includes('check')">
      <div class="q-item" :class="{'active-q-item': item.rData && item.rData.value === '1'}" v-for="item in appraisalGroupDetails" :key="item.id" @click="chooseIt(item)">
        <div class="title">
          <div>{{item.userName}}</div>
        </div>
      </div>
    </div>
    <div class="btn-s"><fm-btn @click="save">提交</fm-btn></div>
  </div>
</template>

<script>
import {
  appraisalRequest as request,
  appraisalGroupDetailRequest,
  appraisalResultDetailRequest
} from '../../api'

export default {
  data () {
    return {
      loading: false,
      chooseIndex: 0,
      chooseBkh: null,
      showCurrent: true,
      dfvalue: null,
      userType: [],
      appraisalGroupDetails: [],
      appraisalId: null,
      mxList: [],
      appraisalGroupDetailId: null,
      appraisalData: null
    }
  },
  computed: {
    workerId () {
      return this.$store.getters.currentWorkerId
    },
    statusList () {
      return this.$store.getters.appraisalStatusList
    },
    appraisalGroupDetailStatusList () {
      return this.$store.getters.appraisalGroupDetailStatusList
    },
    maxVote () {
      return this.appraisalData && this.appraisalData.rule && !isNaN(this.appraisalData.rule) ? Number(this.appraisalData.rule) : null
    }
  },
  beforeRouteEnter (to, from, next) {
    if (to.query && to.query.id) {
      next(vm => vm.$nextTick(vm.init))
    } else {
      next(from)
    }
  },
  methods: {
    async init () {
      this.appraisalId = this.$router.history.current.query.id
      this.loadData()
      await this.loadUserType()
      if (this.userType.includes('check')) {
        this.loadDetailList()
      } else {
        this.$notice.info({
          title: '系统提示',
          desc: '无考核权限'
        })
      }
    },
    chooseIt (item) {
      if (item.rData.value !== '1') {
        let num = this.appraisalGroupDetails.filter(v => v.rData && v.rData.value === '1').length
        if (this.maxVote && (num > this.maxVote || num === this.maxVote)) {
          this.$notice.info({
            title: '系统提示',
            desc: '已超过最大可投票数，请先取消其他投票再投给新未投人。'
          })
        } else {
          item.rData.value = '1'
        }
      } else {
        item.rData.value = '0'
      }
    },
    async save () {
      let voteNum = this.appraisalGroupDetails.filter(v => v.rData && v.rData.value === '1').length
      if (voteNum < this.maxVote) {
        let result = await this.$dialog.confirm({title: '系统提示', content: '是否放弃未投的' + (this.maxVote - voteNum) + '票？'})
        if (!result) {
          return
        }
      }
      this.loading = true
      let datas = this.appraisalGroupDetails.map((v) => {
        let dataItem = {}
        if (v.rData) {
          dataItem = {
            id: v.rData.id ? v.rData.id : undefined,
            appraisalId: this.appraisalId,
            beAppraisalGroupDetailId: v.id,
            appraisalGroupDetailId: this.appraisalGroupDetailId,
            value: v.rData.value
          }
        }
        return dataItem
      })
      let i = 0
      while (i < datas.length) {
        if (datas[i].id) {
          await appraisalResultDetailRequest.update(datas[i].id, datas[i])
        } else {
          await appraisalResultDetailRequest.add(datas[i])
        }
        i += 1
      }
      this.$notice.success({
        title: '系统提示',
        desc: '完成'
      })
      this.loading = false
      this.loadMxList2()
    },
    async loadMxList2 () {
      let parm = {
        appraisalGroupDetailId: this.appraisalGroupDetailId
      }
      let mxList = await appraisalResultDetailRequest.get(parm)
      this.appraisalGroupDetails.forEach((v) => {
        v.rData = mxList.find(v2 => v.id === v2.beAppraisalGroupDetailId) || {
          value: '0'
        }
      })
    },
    async loadUserType () {
      let parm = {
        appraisalId: this.appraisalId,
        workerId: this.workerId ? undefined : -1
      }
      let data = await appraisalGroupDetailRequest.getMy(parm)
      let userType = []
      data.forEach((v) => {
        if (!userType.includes(v.appraisalGroupType)) {
          userType.push(v.appraisalGroupType)
        }
        if (v.appraisalGroupType === 'check') {
          this.appraisalGroupDetailId = v.id
        }
      })
      this.userType = userType
    },
    loadData () {
      this.loading = true
      let parm = {id: this.appraisalId}
      request.get(parm).then((data) => {
        data.forEach(v => v.statusText = (this.statusList.find(v1 => v1.key === v.status) || {label: ''}).label)
        if (data.length == 0) {
          this.$notice.info({
            title: '系统提示',
            desc: '考核不存在'
          })
        } else {
          this.appraisalData = data[0]
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    async loadDetailList () {
      let parm = {
        appraisalId: this.appraisalId,
        appraisalGroupType: 'becheck'
      }
      appraisalGroupDetailRequest.get(parm).then((data) => {
        data.sort((a, b) => (a.orderNum || 1000) - (b.orderNum || 1000))
        data.forEach((v) => {
          v.statusText = (this.appraisalGroupDetailStatusList.find(v1 => v1.key === v.status) || {label: ''}).label
          v.rData = {
            value: '0'
          }
        })
        this.appraisalGroupDetails = data
        this.loadMxList2()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.btn-s {
  display: flex;
  justify-content: space-around;
  margin: 1rem 0;
}
.a-detail {
  .fm-input-new {
    width: 142px !important;
    height: 32px;
    border-radius: 16px;
    border: 1px solid #585858;
  }
  .bkhr-btns {
    .fm-btn-norm {
      height: 32px !important;
      width: 96px !important;
      border-radius: 16px !important;
    }
  }
}
</style>


<style lang="less" scoped>
.active-q-item {
  border-color: #F4628F !important;
  color: #F4628F;
}
.q-item {
  border: 1px solid #fff;
  width: 10rem;
  height: 2rem;
  background: #fff;
  margin: 14px 14px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 28px;
  .title {
    display: flex;
    justify-content: space-between;
  }
}
.br-t {
  font-size: 14px;
  color: rgba(0,0,0,0.65);
  line-height: 36px;
  display: flex;
  div {
    margin: 9px 9px 0 2px;
    transform: rotate(180deg);
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      color: rgba(0,0,0,0.65) !important;
    }
  }
}
.show-all-btn {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  div {
    line-height: 22px;
    color: #00C8C4;
    font-size: 14px;
  }
}
.top-c {
  background: #fff;
  padding: 14px 28px;
  .title {
    line-height: 22px;
    font-size: 16px;
    color: #333145;
  }
  .note {
    color: #00C8C4;
  }
  .remark {
    color: rgba(0,0,0,0.45);
    font-size: 14px;
    line-height: 23px;
    padding-top: 14px;
  }
}
.not-text {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: #999;
  font-size: 2rem;
  padding: 0 2rem 4rem;
}
.bkhr-list-none {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
  font-size: 2rem;
}
.bkhr-list-none {
  flex: 1;
}
.bkhr-item {
  position: relative;
  padding: 16px 20px;
  margin: 8px;
  line-height: 2rem;
  background: #FDFDFD;
  color: rgba(0,0,0,0.65);
  line-height: 36px;
  font-size: 14px;
  .yy-fz {
    font-size: 32px;
    line-height: 45px;
    position: absolute;
    top: 0;
    right: 22px;
  }
  .bkhr-btns {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}
.b-c-t {
  display: flex;
  flex: 1;
}
.b-r {
  flex: 1;
  margin: 8px;
  background: #fff;
}
.b-l {
  flex: 1;
}
.a-detail {
  display: flex;
  flex-direction: column;
  background: #F8FBFF;
}
.content {
  flex: 1;
  display: flex;
  overflow-x: auto;
}
.group-item {
  min-width: 30%;
  max-width: 50%;
  flex: 1;
  margin: 1rem;
  border: 1px solid #eee;
}
.bottom-c {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}
.n-g {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: #999;
}
.a-remark {
  color: #777;
  margin: 1rem 2rem;
  line-height: 2;
}
</style>